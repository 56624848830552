import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import './Content3.css';

const Content3 = () => {
  return (
    <Container fluid className="curriculum-section py-5">
      {/* Heading */}
      <Row className="text-center mb-4">
        <Col>
          <h2 className="section-heading">Our Curriculum</h2>
        </Col>
      </Row>

      {/* Image and Paragraph */}
      <Row className="align-items-center">
        {/* Image on the left */}
        <Col md={6} className="mb-4 mb-md-0">
          <Image 
            src="/images/1.jpeg" 
            alt="Curriculum overview" 
            fluid 
            className="curriculum-image" 
          />
        </Col>

        {/* Paragraph on the right */}
        <Col md={6}>
          <div className="curriculum-text">
            <p>
        \Kanchanvidyamandir Public Sr.Sec. School is a dream institution set in lush green expanse, away from the main town and busy roads. It nurtures students as successful and responsible citizens for learning without tormenting them with burden of rote learning. We sensitize the children to feel, observe, listen, understand and respond. Guided by the philosophy of quality education and preparing good citizens. President of K.V. Public Sr.Sec. School, Samaypur, Sec.-56 Ballabgarh, Faridabad.
        The school ensures individual care to each of its students and provides a perfect home for learning with emotional warmth, Indian ambience and modern outlook. There are special features like experiential learning and classes on thinking, observation, memory and communication skills.
            </p>
            <p>
              Whether you are pursuing a career in technology, business, or the arts, our programs 
              are tailored to help you achieve your goals and realize your full potential.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Content3;
