import React from 'react'
import { Carousel } from 'react-bootstrap';
function Banner() {
  return (
    <>
     <Carousel style={{marginTop:"-1rem"}}>
      {/* First Slide */}
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/images/banner1.jpg" // Replace with the correct path to your image
          alt="First slide"
          style={{ height: '500px', objectFit: 'cover' }} // Adjust height as needed
        />
        {/* <Carousel.Caption>
          <h3>First Slide Label</h3>
          <p>Some description for the first slide.</p>
        </Carousel.Caption> */}
      </Carousel.Item>

      {/* Second Slide */}
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/images/banner2.jpg" // Replace with the correct path to your image
          alt="Second slide"
          style={{ height: '500px', objectFit: 'cover' }} // Adjust height as needed
        />
        {/* <Carousel.Caption>
          <h3>Second Slide Label</h3>
          <p>Some description for the second slide.</p>
        </Carousel.Caption> */}
      </Carousel.Item>

      {/* Third Slide */}
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/images/banner3.jpg" // Replace with the correct path to your image
          alt="Third slide"
          style={{ height: '500px', objectFit: 'cover' }} // Adjust height as needed
        />
        {/* <Carousel.Caption>
          <h3>Third Slide Label</h3>
          <p>Some description for the third slide.</p>
        </Carousel.Caption> */}
      </Carousel.Item>
    </Carousel></>
  )
}

export default Banner