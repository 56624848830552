import React from 'react'
import Header from '../components/Header'
import  Navbar  from '../components/Navbar'
import Banner from '../components/Banner'
import Content1 from '../components/Content1'
import Content2 from '../components/Content2'
import Content3 from '../components/Content3'
import Footer from '../components/Footer'
function Home() {
  return (
    <>
    <Header></Header>
    <Navbar></Navbar>
    <Banner></Banner>
    <Content1></Content1>
    <Content2></Content2>
    <Content3></Content3>
    <Footer></Footer>
    </>
  )
}

export default Home