import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import './Content2.css';

const Content2 = () => {
  return (
    <Container fluid className="box-slider py-5">
      {/* Heading */}
      <Row className="text-center mb-4">
        <Col>
          <h2 className="section-heading">Our Key Services</h2>
        </Col>
      </Row>

      {/* Desktop View: 3 boxes in a row */}
      <Row className="d-none d-md-flex justify-content-center">
        <Col md={4} className="box-item">
          <div className="box-content">
            <h3>Innovative Solutions</h3>
            <p>
              HALF Olympic-size swimming pool with 4ft depth and a lifeguard.
              Artificial Turf Football Ground, Indoor Zone for Table Tennis,
              Gymnastics, Basket Ball Court, Badminton Court, Netted Cricket
              Pitch, Sand Pit, Organic Farming, Kids Play Station. Separate
              libraries for Primary & Secondary wings.
            </p>
          </div>
        </Col>
        <Col md={4} className="box-item">
          <div className="box-content">
            <h3>Strategic Growth</h3>
            <p>
              HALF Olympic-size swimming pool with 4ft depth and a lifeguard.
              Artificial Turf Football Ground, Indoor Zone for Table Tennis,
              Gymnastics, Basket Ball Court, Badminton Court, Netted Cricket
              Pitch, Sand Pit, Organic Farming, Kids Play Station. Separate
              libraries for Primary & Secondary wings.
            </p>
          </div>
        </Col>
        <Col md={4} className="box-item">
          <div className="box-content">
            <h3>Trusted Partnerships</h3>
            <p>
              HALF Olympic-size swimming pool with 4ft depth and a lifeguard.
              Artificial Turf Football Ground, Indoor Zone for Table Tennis,
              Gymnastics, Basket Ball Court, Badminton Court, Netted Cricket
              Pitch, Sand Pit, Organic Farming, Kids Play Station. Separate
              libraries for Primary & Secondary wings.
            </p>
          </div>
        </Col>
      </Row>

      {/* Mobile View: Slider */}
      <Row className="d-md-none">
        <div className="slider-container">
          <div className="box-item">
            <div className="box-content">
              <h3>Innovative Solutions</h3>
              <p>
                HALF Olympic-size swimming pool with 4ft depth and a lifeguard.
                Artificial Turf Football Ground, Indoor Zone for Table Tennis,
                Gymnastics, Basket Ball Court, Badminton Court, Netted Cricket
                Pitch, Sand Pit, Organic Farming, Kids Play Station. Separate
                libraries for Primary & Secondary wings.
              </p>
            </div>
          </div>
          <div className="box-item">
            <div className="box-content">
              <h3>Strategic Growth</h3>
              <p>
                HALF Olympic-size swimming pool with 4ft depth and a lifeguard.
                Artificial Turf Football Ground, Indoor Zone for Table Tennis,
                Gymnastics, Basket Ball Court, Badminton Court, Netted Cricket
                Pitch, Sand Pit, Organic Farming, Kids Play Station. Separate
                libraries for Primary & Secondary wings.
              </p>
            </div>
          </div>
          <div className="box-item">
            <div className="box-content">
              <h3>Trusted Partnerships</h3>
              <p>
                HALF Olympic-size swimming pool with 4ft depth and a lifeguard.
                Artificial Turf Football Ground, Indoor Zone for Table Tennis,
                Gymnastics, Basket Ball Court, Badminton Court, Netted Cricket
                Pitch, Sand Pit, Organic Farming, Kids Play Station. Separate
                libraries for Primary & Secondary wings.
              </p>
            </div>
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default Content2;
