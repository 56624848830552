import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer-section py-4">
      <Container>
        <Row>
          {/* About Us Section */}
          <Col md={4} className="footer-col mb-4 mb-md-0">
            <h4>About Us</h4>
            <p>
            Kanchanvidyamandir Public Sr.Sec. School is fully committed to impart the value based education & is focusing on the etiquettes and manners along with the studies..
            </p>
          </Col>

          {/* Contact Us Section */}
          <Col md={4} className="footer-col mb-4 mb-md-0">
            <h4>Contact Us</h4>
            <ul className="list-unstyled">
              <li>Email: kvschool0012@gmail.com</li>
              <li>Phone: +91 99539 40222</li>
              <li>Address: 
              Sector-56, Faridabad, HR, India.</li>
            </ul>
          </Col>

          {/* Social Media Links Section */}
          <Col md={4} className="footer-col">
            <h4>Follow Us</h4>
            <ul className="social-links list-inline">
              <li className="list-inline-item">
                <a href="#" className="social-link"><i className="fab fa-facebook-f"></i></a>
              </li>
              <li className="list-inline-item">
                <a href="#" className="social-link"><i className="fab fa-twitter"></i></a>
              </li>
              <li className="list-inline-item">
                <a href="#" className="social-link"><i className="fab fa-instagram"></i></a>
              </li>
              <li className="list-inline-item">
                <a href="#" className="social-link"><i className="fab fa-linkedin"></i></a>
              </li>
            </ul>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col className="text-center">
            <p className="mb-0" style={{fontWeight:"500",color:"black"}}>&copy; 2024 Copyright: Kanchanvidyamandir.com</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
