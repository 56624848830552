import React from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import './Content1.css';  // Custom CSS for additional styles

const Content1 = () => {
  return (
    <Container fluid className="content-section py-5">  {/* Changed to fluid for full width */}
      <Row className="align-items-center">
        {/* Left Column - Text */}
        <Col lg={7} className="text-section">  {/* Widened for larger screens */}
          <h2 className="mb-4 text-heading">K.V SR.SEC. SCHOOL</h2>
          <p className="text-muted">
          Kanchanvidyamandir Public Sr.Sec. School is a dream institution set in lush green expanse, away from the main and busy roads. It nurtures students as successful and responsible citizens for learning without tormenting them with burden of rote learning. We sensitize the children to feel, observe, listen, understand and respond. Guided by the philosophy of quality education and preparing good citizens. President of K.V. Public Sr.Sec. School, Samaypur, Sec.-56 Ballabgarh, Faridabad.
The school ensures individual care to each of its students and provides a perfect home for learning with emotional warmth, Indian ambience and modern outlook. There are special features like experiential learning and classes on thinking, observation, memory and communication skills.

          </p>
          <Button variant="primary" className="mt-3">
            Learn More
          </Button>
        </Col>

        {/* Right Column - Image */}
        <Col lg={5} className="image-section">  
          <img
            src="/images/1.jpeg"  // Replace with your image path
            alt="Descriptive Text"
            className="img-fluid rounded shadow"
            style={{ maxHeight: '500px', objectFit: 'cover' }} 
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Content1;
