import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Header= () => {
  return (
    <Container className="d-flex justify-content-center align-items-center" style={{ height: '20vh' }}>
      <Row>
        <Col className="text-center">
          <img 
            src="/images/logo.png" // Replace with the actual name of your image
            alt="Logo" 
            className="img-fluid mb-3" 
            style={{ maxWidth: '150px' }} // Adjust maxWidth to your desired size
          />
          <h5 style={{ fontSize: '2rem',marginTop:"-2rem",fontWeight:"600" }}>Kanchan Vidya Mandir</h5> {/* Adjust text size here if needed */}
        </Col>
      </Row>
    </Container>
  );
};

export default Header;
