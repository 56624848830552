import React from 'react';
import { Navbar as BootstrapNavbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import "./navbar.css"
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <BootstrapNavbar bg="light" expand="lg" className="mb-4 navbar1" style={{ height: '50px' ,background:"yellow"}}>
      <Container >
        {/* <BootstrapNavbar.Brand as={Link} to="/" style={{ fontSize: '1rem' }}>BrandName</BootstrapNavbar.Brand> */}
        <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" style={{backgroundColor:"yellow" }} />
        <BootstrapNavbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto" style={{ gap: '5rem' , fontSize:"20px",fontWeight:"500"}}>  {/* Apply gap here */}
            <Nav.Link as={Link} to="/">Home</Nav.Link>
            <Nav.Link as={Link} to="/about">Gallery</Nav.Link>
            {/* <Nav.Link as={Link} to="/services">Services</Nav.Link> */}
            <Nav.Link as={Link} to="/contact">Career</Nav.Link>

            <NavDropdown title="Admissions" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="/products/category1">Fee Structure</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/products/category2">Admission Rules</NavDropdown.Item>
              {/* <NavDropdown.Item as={Link} to="/products/category3">Category 3</NavDropdown.Item> */}
            </NavDropdown>

            <NavDropdown title="About-Us" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="/Vision & Mission">Vission & Mission</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/About Us">About School</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="Chairman's message">Chairman's message</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="Principal's message">Principal's message</NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Academics" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="/support/faq">Syllabus</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/support/contact">Datesheet</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="Uniform">Uniform</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="Curriculum">Curriculum</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="Actitvities">Activities</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </BootstrapNavbar.Collapse>
      </Container>
    </BootstrapNavbar>
  );
};

export default Navbar;
